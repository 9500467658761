import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled, { css, createGlobalStyle, ThemeProvider } from 'styled-components'

const givenPages = ["", 'contact', 'skills']
const Layout = ({ path, children }) => {
  const [arrowNames, setArrowNames] = useState(['contact', 'skills'])

  useEffect(() => {
    const parts = path.pathname.split('/')
    const pageName = parts[parts.length - 1]
    setArrowNames(givenPages.filter(x => x !== pageName))
  }, [path.pathname])

  if(
   path.pathname !== '/contact' &&
   path.pathname !== '/contact/' &&
   path.pathname !== '/skills/' &&
   path.pathname !== '/skills' &&
   path.pathname !== '/' 
  ) {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        
        
            {children}
         
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <StyledDiscoVideo preload="auto" loop autoPlay muted>
        <source src={'https://res.cloudinary.com/dqaak8qlv/video/upload/v1582663692/personal/disco-video.mp4'/* DiscoVideo */} type="video/mp4" />
      </StyledDiscoVideo>
      <MainWrapper>
        <LeftArrowWrapper>
          <LeftArrow title={arrowNames[0]} />
        </LeftArrowWrapper>
        <RightArrowWrapper>
          <RightArrow title={arrowNames[1]} />
        </RightArrowWrapper>
        
        <MainContent path={path}>
          {children}
        </MainContent>
      </MainWrapper>
    </ThemeProvider>
  )
}

const theme = {
  lightPurple: '#b888ef',
  purpleColor: '#530357',
  circleSize: '100px',
}

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
  }

  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  html {
    box-sizing: border-box;
  }
`

const LeftArrow = ({ title }) => (
  <Link
    style={{textDecoration: 'none'}}
    to={title}
  >
    <StyledArrow left>
      <ArrowContent left>
        {title === '' ? 'bio' : title}
      </ArrowContent>
    </StyledArrow>
  </Link>
)

const RightArrow = ({ title }) => (
  <Link
    style={{textDecoration: 'none'}}
    to={title}
  >
    <StyledArrow right>
      <ArrowContent right>
        {title === '' ? 'Bio' : title}
      </ArrowContent>
    </StyledArrow>
  </Link>
)

const LeftArrowWrapper = styled.div`
  grid-column: 1;
  grid-row: 4 / 10;

  @media only screen and (max-width: 800px) {
    grid-column: 1 / 7;
    grid-row: 1 / 3;
  }
`

const RightArrowWrapper = styled.div`
  grid-column: 13;
  grid-row: 4 / 10;

  @media only screen and (max-width: 800px) {
    grid-column: 7 / end;
    grid-row: 1 / 3;
  }
`

const ArrowContent = styled.div`
  writing-mode: vertical-rl; 
  font-family: 'Orbitron';
  font-size: 3rem;
  color: ${props => props.theme.lightPurple};
  text-align: center;
  height: 100%;
  margin-left: 10px;

  @media only screen and (max-width: 800px) {
    writing-mode: unset;
    margin-left: 0;
    color: ${props => props.theme.purpleColor};
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const StyledArrow = styled.div`
  height: 100%;
  width: ${props => props.theme.circleSize};
  border-bottom-right-radius: ${props => props.theme.circleSize};
  border-top-right-radius: ${props => props.theme.circleSize};
  ${props => props.right && css`
    transform: rotate(180deg) 
  `};
  
  box-shadow:
      inset 0 0 50px #fff,      
      inset 20px 0 80px #f0f,   
      inset -20px 0 80px #0ff,  
      inset 20px 0 300px #f0f,  
      inset -20px 0 300px #0ff, 
      0 0 50px #fff,            
      -10px 0 80px #f0f,        
      10px 0 80px #0ff;         
  transition: hover .2s ease-out;

  &:hover {
    cursor: pointer;

    box-shadow:
      inset 0 0 50px #fff,      
      inset 20px 0 80px #fbff0f,   
      inset -20px 0 80px #fcff66,  
      inset 20px 0 300px #fbff0f,  
      inset -20px 0 300px #fcff66, 
      0 0 50px #fff,            
      -10px 0 80px #fbff0f,        
      10px 0 80px #fcff66;         
  }

  &:hover ${ArrowContent} {
    color: #d8a270;
  }

  @media only screen and (max-width: 800px) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    transform: rotate(0deg);
    width: 100%;
  }
`

const MainWrapper = styled.main`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  height: 100vh;

  @media only screen and (max-width: 800px) {
    grid-template-rows: repeat(3, 30px) repeat(9, 1fr);
  }
`

const MainContent = styled.div`
  grid-column: 5 / 10;
  grid-row: 2 / 12;
  text-align: center;
  font-family: 'Cabin';
  overflow: ${props => (props.path.pathname !== '/contact') && 'hidden'}; 
  border-radius: 3%;

  @media only screen and (max-width: 800px) {
    grid-row: 4 / end;
    grid-column: 2 / 12;
    border-radius: 0;
  }

  @media only screen and (max-width: 500px) {
    grid-column: 1 / end;
    grid-row: 3 / end;
  }
`

const StyledDiscoVideo = styled.video`
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
